html {
    ms-overflow-style: none;
    scrollbar-width: none;
}
html::-webkit-scrollbar {
    display: none;
}

body {
    background-color: #181818;
}

* {
    box-sizing: border-box;
}

:root {
    --fc-border-color: #414141;
    --fc-button-bg-color: #282828;
}

.fc .fc-button-primary {
    border-color: #606060
}

.fc-daygrid-day-frame {
    padding: 0 5px;
    cursor: pointer;
}

.fc-daygrid-day-frame:hover {
    background: #1f1f1f;
}

.fc .fc-button-primary:hover {
    border-color: #606060;
    background-color: #343434
}

.fc-daygrid-event {
    border-radius: 0;
    padding: 10px;
    background: #282828;
    border-color: #3a3a3a;
    font-size: 18px;
}

.fc-daygrid-event:hover {
    background-color: #343434
}

.fc-toolbar-title {
    text-transform: capitalize;
    color: rgba(255, 255, 255, .4);
}

.fc-theme-standard .fc-popover {
    background: #414141;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(59, 59, 59, 0.15);
}

.fc-daygrid-day-top {
    color: #646464
}

.fc-col-header-cell-cushion {
    text-transform: uppercase;
}

.fc-col-header-cell.fc-day {
    padding: 10px;
    background-color: #282828;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}